<template>
    <el-main v-loading="loading">
        <el-row justify="center">
            <el-col :span="18">
                <el-input
                    ref="text"
                    v-model="text"
                    type="textarea"
                    :placeholder="$t('enterText')"
                    clearable
                />
            </el-col>
        </el-row>
        <el-row justify="end" style="margin: 20px 0">
            <!--            <el-col :span="4">-->
            <!--                <el-upload-->
            <!--                    ref="upload"-->
            <!--                    name="upload"-->
            <!--                    id="upload"-->
            <!--                    accept=".pdf,.txt,.doc,.docx"-->
            <!--                    :limit="1"-->
            <!--                    @change="handleFile"-->
            <!--                    :auto-upload="false"-->
            <!--                    :on-exceed="handleExceed"-->
            <!--                >-->
            <!--                    <el-button type="secondary">Прикрепить файл</el-button>-->
            <!--                </el-upload>-->
            <!--            </el-col>-->
            <el-col :span="4">
                <el-button type="primary" @click="check(text)">{{$t('send')}}</el-button>
            </el-col>
<!--            <el-col :span="2"></el-col>-->
        </el-row>
    </el-main>
</template>

<script>
import { ElMain, ElInput, genFileId } from 'element-plus';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'MainComponent',
    components: {
        ElMain,
        ElInput,
    },
    data() {
        return {
            text: null,
        };
    },
    computed: {
        ...mapState(['id']),
        ...mapGetters(['results', 'loading']),
    },
    methods: {
        ...mapActions({
            checkText: 'checkText',
            checkFile: 'checkFile',
        }),
        check(text) {
            this.checkText(text);
        },
        redirect() {
            this.$router.push({ name: 'results', params: { id: this.id } });
        },
        handleFile(file) {
            this.$refs.text.clear();
            if (file['status'] === 'ready') this.checkFile(file.raw);
        },
        handleExceed(files) {
            this.$refs.upload?.clearFiles();
            const file = files[0];
            file.uid = genFileId();
            this.$refs.upload?.handleStart(file);
        },
    },
    watch: {
        id: function () {
            this.redirect();
        },
    },
};
</script>

<style scoped></style>
