import {createApp} from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Vuex from 'vuex';
import axios from 'axios';

const API_URL = 'https://tattext.ru';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
const app = createApp(App);
const store = new Vuex.Store({
    state: {
        results: {},
        loading: false,
        id: null,
    },
    actions: {
        async checkText({commit}, text) {
            commit('SET_LOADING', true);
            try {
                const response = await axios.post(`${API_URL}/check/`, text);
                commit('SET_ID', response.data.id);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async checkFile({commit}, file) {
            const formData = new FormData();
            formData.append('file', file);
            commit('SET_LOADING', true);
            try {
                const response = await axios.post(`${API_URL}/check/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_RESULTS', response.data);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async updateResults({commit}, id) {
            commit('SET_LOADING', true);
            try {
                const response = await axios.get(`${API_URL}/results/${id}/`);
                commit('SET_RESULTS', response.data);
            } catch (err) {
                console.error(err);
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    mutations: {
        SET_RESULTS(state, results) {
            state.results = results;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ID(state, id) {
            state.id = id;
        },
    },
    getters: {
        results: (state) => state.results,
        loading: (state) => state.loading,
        id: (state) => state.id,
        percent: (state) => {
            if (state.results.analysed) {
                let analysed = Math.round((state.results.analysed / state.results.total) * 100);
                return `Проверка текста: ${analysed}%.`;
            }
        },
    },
});


import MainComponent from './components/Main';
import ResultsComponent from './components/Results';
import {createRouter, createWebHashHistory} from 'vue-router';
import {createI18n} from "vue-i18n";

const routes = [
    {path: '/', component: MainComponent},
    {path: '/:id/results', component: ResultsComponent, name: 'results'},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

const messages = {
    ru: {
        enterText: 'Введите текст',
        send: 'Отправить',
        shingles: 'Уникальность по методу шинглов',
        uniqueShingles: 'Уникальность по методу наиболее встречающихся',
        aiDetected: 'Процент ИИ генерации',
        authorsCount: 'Предполагаемое количество авторов',
        reference: 'Справка',
        sources: 'Обнаруженные источники',
        internetSources: 'Обнаруженные источники из интернета',
        notFound: 'Источники не обнаружены',
        projectInfo: 'Проект разрабатывается сотрудниками НОЦ стратегических исследований в области родных языков и культур',
    },
    tt: {
        enterText: 'Текстны кертегез',
        send: 'Җибәрeргә',
        shingles: 'Шингллар методы буенча уникальлек',
        uniqueShingles: 'Уникаль шингллар методы буенча уникальлек',
        aiDetected: 'ЯИ генерациясе проценты',
        authorsCount: 'Фаразланган авторлар саны',
        reference: 'Белешмә',
        sources: 'Табылган чыганаклар',
        internetSources: 'Интернет-чыганаклар',
        notFound: 'Чыганаклар табылмаган',
        projectInfo: 'Проект Туган телләр һәм мәдәниятләр өлкәсендә стратегик тикшеренүләр НОЦ хезмәткәрләре тарафыннан эшләнә',
    }
};
const i18n = createI18n({
    locale: 'tt', // установить дефолтный язык
    fallbackLocale: 'ru', // установить резервный язык
    messages, // установить объект сообщений с переводами
});

app.use(store);
app.use(ElementPlus);
app.use(router);
app.use(i18n)
app.mount('#app');
