<template>
    <div class="header">
        <el-row class="row-bg" justify="space-between">
            <el-col :span="6">
                <img alt="tulip logo" src="../assets/logo.png" />
                <span :style="`font-size: var(--el-font-size-extra-large);`">
                    {{ msg }}
                </span>
            </el-col>
            <el-col :span="6">
                <el-button size="sm" class="my-2 my-sm-0 btn-info" type="submit" @click="$i18n.locale = 'tt'">
                   <img id="tatlogo" alt="tulip logo" src="Tatarstan.png" />
                </el-button>
                <el-button size="sm" class="my-2 my-sm-0 btn-info" type="submit" @click="$i18n.locale = 'ru'">
                    <img id="rulogo" alt="tulip logo" src="Russia.png" />
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'HeaderComponent',
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

#tatlogo {
    height: 20px !important;
    width: 20px
}

#rulogo {
    height: 20px !important;
    width: 20px
}
</style>
