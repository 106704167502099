<template>
  <div class="common-layout">
    <el-container>
      <el-main :style="`text-align: left; margin-left: 8vw`" v-if="results.ready">
        <div>
          {{ $t('shingles') }}: <b>{{ results.shingles }}%</b>
          <br/>
          {{ $t('uniqueShingles') }}:
          <b>{{ results.most_recents }}%</b>
          <br/>
          {{ $t('aiDetected') }}:
          <b>{{ results.ai_detected }}%</b>
          <br/>
          {{ $t('authorsCount') }}:
          <b>{{ results.authors_count }}</b>
          <br/>
          Жанр:
          <b>{{ results.genre }}</b>
          <br/>
          {{ $t('reference') }}:
          <a :href="results.file">{{results.file}}</a>
          <br/>
        </div>
        <br/>
        <div :style="`text-align: left`">
          <b v-if="results.detected?.length > 0">{{ $t('sources') }}:</b>
          <b v-else>{{ $t('notFound') }}</b>
          <el-row
              justify="left"
              style="margin: 20px 0"
              v-for="(value, key) in results.detected"
              :key="key"
          >
            {{ key + 1 }}.&nbsp;<a :href="value.link">{{ value.title }}</a>
          </el-row>
        </div>
        <div :style="`text-align: left`">
          <b v-if="results.urls?.length > 0">{{ $t('internetSources') }}:</b>
          <el-row
              justify="left"
              style="margin: 20px 0"
              v-for="(value, key) in results.urls"
              :key="key"
          >
            {{ key + 1 }}.&nbsp;<a :href="value.link">{{ value.title }}</a>
          </el-row>
        </div>
      </el-main>
      <el-main v-loading="!results.ready" v-else-if="!results.ready && !loading" :element-loading-text="percent">
      </el-main>
      <el-main v-loading="!results.ready" v-else></el-main>
    </el-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ResultsComponent',

  computed: {
    ...mapGetters(['results', 'loading', 'percent']),
  },
  methods: {
    ...mapActions(['updateResults']),
    async fetchData() {
      if (!this.updateInProgress) {
        this.updateInProgress = true;
        try {
          await this.updateResults(this.$route.params.id);
        } finally {
          this.updateInProgress = false;
        }
      }
    },
    scheduleFetchData() {
      if (!this.results.ready && !this.updateInProgress) {
        setTimeout(() => {
          this.fetchData();
        }, 20000);
      }
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    results: {
      handler(newValue) {
        if (newValue && !newValue.ready) {
          this.scheduleFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>
