<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <Header msg="tattext"/>
      </el-header>
      <router-view class="main"></router-view>
      <el-footer>
        <el-divider border-style="dashed"/>
        <el-row :gutter="20">
          <el-col :span="9">
            <el-space direction="vertical" style="margin-top: 20px">
              <el-link type="primary" href="https://repository.kpfu.ru/?p_id=296439" target="_blank">
               <el-image src="noc_logo.svg"/>
              </el-link>
            </el-space>
          </el-col>
          <el-col :span="6">
            <el-space direction="vertical">
              </el-space>
          </el-col>
          <el-col :span="9">
            <el-space direction="vertical">
              <el-image src="comission_logo.svg"/>
            </el-space>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import {ElContainer, ElHeader} from 'element-plus';
import HeaderComponent from './components/Header';

export default {
  name: 'App',
  components: {
    ElContainer,
    ElHeader,
    Header: HeaderComponent,
  },
  data() {
    return {
      items: [{
        text: 'Новость о татарском антиплагиате разлетелась со скоростью света.',
        link: 'https://vk.com/wall-18477997_18563',
        image: 'https://sun9-51.userapi.com/impg/2gaqn-Zig7j-RipLym80MIGgYdaV5WbmKCs0qA/snSdJbGnLVM.jpg?size=2560x1152&quality=95&sign=cba2d7ab36ac5cd8f07feadf7d5d597c&type=album'
      }, {
        text: 'Новость о татарском антиплагиате разлетелась со скоростью света.',
        link: 'https://vk.com/wall-18477997_18563',
        image: 'https://sun9-59.userapi.com/impg/OIgehestor5LmWP_wn0li9cvv6mOzf5wUDn5BQ/wNO0Du-USVE.jpg?size=2560x1152&quality=95&sign=895916312dd8882f11d41021a36e23d8&type=album'

      }, {
        text: 'Новость о татарском антиплагиате разлетелась со скоростью света.',
        link: 'https://vk.com/wall-18477997_18563',
        image: 'https://sun9-28.userapi.com/impg/pOY1QQnV37-3_0-D-lg8fXhWwwzrGBmDV7B0gg/K6i_8xxaqYE.jpg?size=2560x1152&quality=95&sign=40f6eac6e3336bb1dbdf60d0d2785658&type=album'
      }],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

textarea {
  height: 50vh;
}

main {
  height: 65vh;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
#ifmk_logo {
  margin-right: 10px;
}
</style>
